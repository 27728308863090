@import 'colors';
@import 'mixins';
@import 'breakpoints';
@import 'typography';
@import 'root';

.services-height {
    height: 150px;

    @media #{$md,$sm,$xs} {
        height: auto;
    }
}

.phone-center-text {
    @media #{$md,$sm,$xs} {
        text-align: center;
    }
}

.services-image {
    @media #{$md,$sm,$xs} {
        min-height: fit-content !important;
        margin-bottom: 10px;
    }
}