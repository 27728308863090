.service-swiper {
    background: #000000cc;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    position: fixed;
}

.service-swiper img {
    padding: 10px;
    max-height: 80vh;
    border-radius: 8px;
    object-fit: contain;
    margin: auto;
}

.service-swiper .swiper-slide {
    margin: auto;
}

.service-swiper .swiper-slide div {
    margin: auto;
    width: fit-content;
}


.service-swiper h2 {
    font-size: 20px;
    padding: 20px;
    color: white;
    text-align: center;
}