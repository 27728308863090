@use '../utils' as *;

.section-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
    color: var(--it-common-black);
    margin-bottom: 15px;
    font-family: "Rubik", sans-serif;
    background-color: rgba(255, 213, 62, 0.666);
    display: inline-block;
    padding: 10px 30px;
    border-radius: 30px;

    &.section-white-bg {
        background-color: var(--it-common-white);
    }

    @media #{$xl} {
        font-size: 15px;
    }

    @media #{$md} {
        font-size: 14px;
    }
}

.section-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.3;
    color: var(--it-common-white);

    & span {
        background-color: var(--it-theme-1);
        padding: 0 5px;
    }

    & i {
        font-style: normal;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            bottom: 6px;
            left: 0;
            height: 6px;
            width: 100%;
            background-color: var(--it-theme-1);
            border-radius: 20px;

            @media #{$xs} {
                bottom: 2px;
            }

        }
    }

    @media #{$xxl} {
        font-size: 37px;
    }

    @media #{$xl} {
        font-size: 38px;
    }

    @media #{$lg} {
        font-size: 35px;

        & br {
            display: none;
        }
    }

    @media #{$md} {
        & br {
            display: none;
        }

        font-size: 40px;
    }

    @media #{$xs} {
        & br {
            display: none;
        }

        font-size: 32px;
    }

    @media #{$xxs} {
        & br {
            display: none;
        }

        font-size: 27px;
    }


}

.section-blue-subtitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.1;
    color: var(--it-common-blue-4);
    margin-bottom: 10px;
    border-bottom: 1px solid var(--it-common-blue-4);
    display: inline-block;
    font-family: "Rubik", sans-serif;

    @media #{$xl} {
        font-size: 20px;
    }

    @media #{$lg} {
        font-size: 18px;
    }

    @media #{$xs} {
        font-size: 20px;
    }
}

.hero-title {
    font-weight: 700;
    font-size: 60px;
    line-height: 1.3;
    color: var(--it-common-white);

    & span {
        background-color: var(--it-theme-1);
        padding: 0 5px;
        -webkit-text-stroke: 2px var(--it-common-white);
        color: transparent;

    }

    @media #{$xxxl} {
        font-size: 54px;
    }

    @media #{$xxl} {
        font-size: 54px;
    }

    @media #{$xl} {
        font-size: 48px;
    }

    @media #{$lg} {
        font-size: 38px;
    }

    @media #{$md} {
        font-size: 38px;
    }

    @media #{$xs} {
        font-size: 35px;
    }

    @media #{$xxs} {
        font-size: 28px;
    }
}

.hero-2-title {
    font-weight: 700;
    font-size: 54px;
    line-height: 1.3;
    color: var(--it-common-black);
    padding-bottom: 15px;

    @media #{$xl} {
        font-size: 48px;
    }

    @media #{$lg} {
        font-size: 38px;
    }

    @media #{$xs} {
        font-size: 37px;
    }

    @media #{$xxs} {
        font-size: 32px;
    }

    & span {
        position: relative;

        &::after {
            position: absolute;
            bottom: -3%;
            left: 0;
            content: '';
            height: 6px;
            width: 92%;
            background-color: var(--it-theme-1);
            border-radius: 20px;
            animation: section-animation 4s infinite;
        }
    }
}

.hero-3-title {
    font-weight: 700;
    font-size: 50px;
    line-height: 1.3;
    color: var(--it-common-black);
    padding-bottom: 15px;

    @media #{$xxxl} {
        font-size: 45px;
    }

    @media #{$xxl} {
        font-size: 40px;
    }

    @media #{$xl} {
        font-size: 38px;
    }

    @media #{$lg} {
        font-size: 37px;

        & br {
            display: none;
        }
    }

    @media #{$md} {
        font-size: 35px;

        & br {
            display: none;
        }
    }

    @media #{$xs} {
        font-size: 32px;

        & br {
            display: none;
        }
    }

    @media #{$xxs} {
        font-size: 30px;

        & br {
            display: none;
        }
    }
}

.hero-4-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 1.5;
    color: var(--it-common-black);
    padding-bottom: 15px;

    @media #{$xxxl} {
        font-size: 45px;
    }

    @media #{$xxl} {
        font-size: 41px;
    }

    @media #{$xl} {
        font-size: 40px;
    }

    @media #{$lg} {
        font-size: 41px;

        & br {
            display: none;
        }
    }

    @media #{$md} {
        font-size: 37px;

        & br {
            display: none;
        }
    }

    @media #{$xs} {
        font-size: 35px;

        & br {
            display: none;
        }
    }

    @media #{$xs} {
        font-size: 32px;

        & br {
            display: none;
        }
    }

    & span {
        background-color: #95b8ff;
        color: var(--it-common-black);
        padding: 0 10px;
    }
}

.hero-5-title {
    font-weight: 700;
    font-size: 42px;
    line-height: 1.5;
    color: var(--it-common-black);
    padding-bottom: 15px;

    @media #{$xxxl} {
        font-size: 45px;
    }

    @media #{$xxl} {
        font-size: 38px;
    }

    @media #{$xl} {
        font-size: 36px;
    }

    @media #{$lg} {
        font-size: 32px;

        & br {
            display: none;
        }
    }

    @media #{$md} {
        font-size: 30px;

        & br {
            display: none;
        }
    }

    @media #{$xs} {
        font-size: 28px;

        & br {
            display: none;
        }
    }

    & span {
        background-color: #95b8ff;
        color: var(--it-common-black);
        padding: 0 10px;
    }
}

.section-title-fixed-width {
    max-width: 650px;
    margin: 0 auto;
}